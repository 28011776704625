@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.wrapper {
	height: inherit;
	width: inherit;

	&:global(.open .dropdown-toggle) {
		&:before {
			content: " ";
			position: absolute;
			.TriangleTop(14px, @black);
			left: 50%;
			margin-left: -14px;
			bottom: 13px;
			z-index: 5001;
		}
	}
	:global(.dropdown-menu) {
		padding: 0;
		top: 7.7em !important;

		@media all and (max-width:  @viewPortMedWidth) {
			top: 8.2em !important;
		}

		@media all and (max-width: @viewPortSmallWidth) {
			top: 12.4em !important;
		}
	}
}

.dropdown-menu {
	padding: 0;
	margin: 0;
	right: -4.4em !important;
}

.uploadBtn {
	border: none;
	border-radius: 0 !important;

	&:hover {
		border: none !important;
	}
}
