.admin {
	.table {
		color: #555;
		background: #f1f2f3;

		tbody > tr.new >td {
		    background: @blackFade05;
		}
	}

	thead th > a {
		color: fade(@white, 70%);
	}

	.sort-col > a {
		&.active{
			font-weight: bold;

			> .caret {
				display: inline-block;
			}
		}

		> .caret {
			display: none;
		}
	}

	.tree-container {
		&:hover > div {
		    background-color: rgba(0,161,240,0.1);
		}

		&.selected:hover > div {
		    background-color: @white;
		}
	}
}

.tree-container > div  {
	background: @blackFade05;
	border: none;
	border-color: @blackFade05;

	&:first-child  {
		border-color: @blackFade05;
	}
}

.table-cell-library-name {
	a {
		&:extend(.WordBreak);
		color: @color1;
		font-weight: bold;
	}

	span {
		font-weight: normal;
	}
}

.admin-table tbody tr {
	th,
	td {
		&:extend(.WordBreak);
	}
}

.item-icon-label {
	margin-right: 5px;
	font-size: 14px;
}

.api-key-cell {
	&-1,
	&-2 {
		width: 20%;
	}

	&-3 {
		text-align: right !important;
		white-space: nowrap;
	}

    &-4 {
        width:30%;
    }
}