@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.root {
	width: 100%;
	height: 100%;

	:global(.tableViewRow) {
		cursor: pointer;
	}

	&.defaultCursor {
		:global(.tableViewRow) {
			cursor: default;
		}
	}

	&.grabPointer {
		:global(.tableViewRow) {
			cursor: grab;
		}
	}


	//it's hard to implement checkbox theming using css variable.So, doing it here.
	:global(header-select-all-check-box-renderer) {
		padding-top: 2px;
	}
	:global(.ag-checkbox-input-wrapper) {
		background-color: @white !important;

		&:global(.ag-checked) {
			background-color: var(--theme-accent-txt) !important;
			border: 2px solid var(--theme-primary-txt);
			font-size: 14px !important;
			line-height: 13px !important;

			&:after {
				color: var(--theme-accent) !important;
			}
		}
		&:after {
			color: var(--theme-primary-txt) !important;
		}
	}

	:global(.ag-row-selected) {
		&:global(.ag-row-hover) {
			:global(.ag-cell) {
				background-color: var(--theme-primary);
			}
		}
	}

}

.inactive {
	.image-wrapper img {
		opacity: 0.5;
	}
}

.headerClass {
	text-transform: uppercase;
}

.cellClass {
	padding: 10px 0 10px 23px !important;
	line-height: 1 !important;
	&:first-child {
		padding-left: 0 !important;
	}
}

.chkBoxHeaderCellClass {
	padding-left: 0 !important;
	:global(vb-ui-checkbox) {
		padding-left: 0 !important;
	}
}

.checkBoxCellClass {
	padding: 10px 0 !important;
	:global(.ag-selection-checkbox) {
		align-items: flex-start !important;
	}
}

.dragCellClass {
	composes: cellClass;

	:global(.ag-cell-wrapper) {
		align-items: flex-start !important;

		:global(.ag-drag-handle) {
			align-items: flex-start !important;
			margin-top: -2px;
			margin-right: 10px;

			:global(.ag-icon) {
				font-family: 'Glyphicons Regular' !important;

				&:before {
					content: '\E159' !important;
				}
			}
		}

	}
}

.cellPseudoAnchor {
	color: var(--theme-primary-txt);
	white-space: normal;
	&:hover, &:active {
		color: var(--theme-primary-txt);
		text-decoration: none;
	}
}
