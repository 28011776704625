@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.layout {
	padding: 20px;
	position: relative;
	max-height: 650px;
	min-height: 180px;
	border-bottom: 1px solid;
	border-color: var(--theme-primary-txt-fade-50);
}

.header {
	margin-top: -10px;
	display: flex;
}

.headerText {
	composes: theme-accent-border from global;
	color: var(--theme-primary-txt) !important;

	.headerLink {
		color: var(--theme-primary-txt) !important;
	}
}

.headerLink:hover {
	color: inherit;
	text-decoration: none;
}
