.data-table {
    td,
    th {
        vertical-align: middle !important;
    }

    tbody a {
        color: @color1MedText;
    }

    &.table-hover tbody > tr:hover a {
        color: @accentColor;
    }
}

.control-label {
	color: @color1LightText;
    font-weight: normal;
    margin-bottom: 4px;

    h4 & {
        color: #acaeb0;
    }

    &,
    .form-horizontal & {
        text-align: left;
        margin-bottom: 4px;
    }

	&.has-error {
		color: @warningColor;
	}
}

.required-message {
    &-small {
        font-size: @type14;
        font-style: italic;
        padding-left: 15px;
    }
}

select.form-control {
    background: @white;
   .BoxShadow(0 1px 1px @blackFade10);

}

.panel-heading .pull-right input[type=checkbox] {
    margin: 4px 0 0;
}

h4 .form-group.pull-right {
    position: relative;
    top: -10px;
}


/* settings */
.description-block {
    padding-top: 10px;
    color: @color1LightText;
}

.checkbox-single {
    margin-right: 10px;
    font-weight: 400;
    line-height: 29px;
}

.btn-link:active
.btn-link:focus {
    border: none;
}

.admin {
    .report-view .form-group {
        padding: 8px 0;
    }

    .form-group {
        &.pull-right,
        &.pull-left {
            margin: 0;
        }

        &.pad-left {
            padding-left: 15px !important;
            padding-bottom: 10px !important;
        }
    }

    .editableform .form-group {
        padding: 0;
    }

    .fieldset {
        margin: 15px 0;
        border: 1px solid @borderColor;

        .form-group {
            margin: 0;
        }
    }

    .new-panel {
        .required .required-field,
        .has-success .success-field,
        .has-error .error-field {
            &:before {
                left: inherit;
                right: 0;
                top: 30px;
            }
        }

        .help-block {
            height: 22px;
        }
    }

    .tab-content {
        border-top: 0;
        .RoundedBottomShape(4px);
    }

    .admin select.form-control {
        .VerticalGradient(@white, #f8fbfd);
        .BoxShadow(0 1px 2px fade(@black, 15%));
    }
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    cursor: not-allowed;
    background-color: @color2Lighten05;
    border: none;
}

.admin-content .nav-tabs a {
    padding: 8px 12px;
}

textarea.form-control {
    height: auto;
    resize: vertical;

    &,
    .has-success & {
        height: auto;
    }
}

.row-margin {
    margin: 20px -10px;
}

.progress-bgColor {
    background-color: #DDD;
}

[uib-typeahead-popup].dropdown-menu{
	max-height: 300px;
	overflow-y: auto;
	right: 16px;

	.active > a{
		background-color: #d8f0fc;
		color: @darkBlue;
		border-color: @accentColor;
	}

	a {
		border-left: 4px solid transparent;
		line-height: 17px;
	}

}

@media (max-width: 768px) {
    .table-cell-width-small {
        width: 30%;
        text-align: center !important;
    }
}

@media(max-width: 480px) {
    .table-status {
        text-align: center;
    }
}