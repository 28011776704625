@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.root {
	height: 85vh;
	display: flex;
	flex-direction: column;
}

.tabPanel {
	padding: 20px;
	height: 100%;
	[role="tabpanel"] {
		height: 100%;

		vb-ui-tab {
			height: 100%;
		}
	}
}

.dataGrid {
	height: 100%;

	:global(.ag-center-cols-container) {
		width: 100% !important;
	}
}

.categoryContainer {
	display: flex;
	flex-direction: column;

	span {
		font-size: smaller;
		height: 23px;
		line-height: 24px;

		strong {
			font-size: medium;
		}
	}
}