@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.header {
	.displayFlex();
	padding: 20px;
	background-color: @black;
}

.main {
    background-color: @color2Lighten03;
    height: 90vh;
}

.downloadWrapper {
    padding: 30px;
}

.downloadIcon {
    padding: 5px;
    .RoundedShape(50%);
    color: @white;
    background-color: @accentColor;
}

.downloadLink {
    &:hover {
        text-decoration: none;
    }
}

