@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.dropdownList {
	background: @color2Lighten05;

	li > a {
		padding: 5px 0 5px 25px !important;
		position: relative;

		> span {
			position: absolute;
			top: 7px;
			left: 6px;
		}
	}
}

.activeAnchor {
	font-weight: bold;
}
