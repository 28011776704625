@import (reference) '~rev-less/global/mixins';

vb-caret {
	display: inline;
	font-size: @type10;
	.Transform(rotate(90deg) scaleY(1.5));
}

[vbCaretDirective] {
	display: inline-block;
	margin-left: 4px;
	font-size: @type10 !important;
	.Transform(rotate(90deg) scaleY(1.5));
}