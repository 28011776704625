.admin {
	.tabbed-panel .nav-tabs {
		background: #d0d2d3;

		> li {
			> a:hover {
				color: @accentColor;
				text-decoration: none;
				background: @adminPanel;
			}

			&.active > a:hover {
				color: @color1MedText;
				text-decoration: none;
				background: @adminPanel;
			}
		}
	}

	.fancy-tabs .nav-tabs {
		background: none;

		> li {
			a {
				padding: 15px 5px;
				text-shadow: none;
				height: auto;
				bottom: 0;
				text-align: center;
				margin: 0;
				font-size: 14px;
			}

			&.active a {
				background: rgb(187,189,191);
				border-bottom: 1px solid rgb(187,189,191) !important;
				text-shadow: none;
				color: @color1;
			}
		}
	}
}

.fancy-tabs {
	a {
		.icon-and-badge {
			font-size: 18px;
			line-height: 18px;
		}

		.text-label {
			display: block;
			margin-top: 8px;
			font-weight: bold;
		}

		.badge {
			background: @accentColor;
			font-size: 13px;
			top: -4px;
			position: relative;
		}
	}

	.nav-tabs.nav-justified  {
		> a {
			border-color: transparent;
		}

		> .active > a {
			&,
			&:hover,
			&:focus {
				&:extend(.fancy-tabs .nav-tabs.nav-justified > a);
			}
		}

		> li {
			display: table-cell;
			width: 1%;
		}
	}
}


@media(max-width: 600px) {
	.admin {
		.fancy-tabs .nav-tabs {
			margin-top: 0;
			padding: 0;
		}

		.panel-heading-with-tabs-fix .nav-tabs li {
			width: 50%;
			display: inline-block;
			margin: 0;

			a {
				border: 1px solid fade(@black, 20%) !important;
				.RoundedShape(0);
			}
		}
	}
}
