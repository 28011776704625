@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

playlist-sharing {
	display: block;
	padding: 16px;

	h4 {
		display: inline;
	}

	share-link,
	share-embed {
		color: @black;

		label,
		.btn-white {
			color: @black;
		}

		.textarea-bottom-controls {
			background-color: @backgroundFade50;
		}
	}

	share-embed {
		.embed-width-cell {
			width: 50%;
		}
	}

	.shareLabel {
		max-width: 40%;
		padding-top: 10px;
		margin-right: 10px;
	}

	.radioBtnGroup {
		max-width: 55%;
		margin-left: auto;
	}

	.shareView {
		.flex(1 1 100%) !important;
		margin-top: 15px;
		max-width: 100%;
	}
}
