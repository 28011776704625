@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.header {
	border-bottom-style: solid;
	border-bottom-width: 1px;
	margin: 1rem 0;
}

.headerText {
	padding: 10px 8px;
	margin: 0;
	font-size: @type18;
	.TextClip;
}
