@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.tilesContainer {
	background: var(--theme-primary);
	display: flex;
	flex-flow: row wrap;
}

.tile {
	margin: 0px 8px;
	flex: 0 1 calc(20% - 16px);
	max-width: calc(20% - 16px);
	max-height: calc(20% - 16px);

	@media(max-width:1200px) {
		flex: 0 1 calc(33% - 16px);
		max-width: calc(33% - 16px);
		max-height: calc(33% - 16px);
	}

	@media(max-width:768px) {
		margin: 0;
		flex: 0 1 100%;
		max-width: 100%;
		max-height: 100%;
	}

}