@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

portal-dashboard {
	display: block;
	height: 100%;

	.dashboard {
		&.feature {
			padding-top: @typeUnit1-2x;
		}

		.greeked-text {
			background: @blackFade05;
			.RoundedShape(2px);
			min-width: 150px;
			display: inline-block;
			height: 10px;

			h3 & {
				height:	13px;
				width: 75%;

				.table-item & {
					margin-top: 17px;
				}
			}

			h4 & {
				height: 10px;
				min-width: 75px;
				width: 50%;
				line-height: 1.2;
			}
		}

		.anchored-thumbnail {
			width: 100%;
		}

		.fill-thumbnail {
			max-height: 100%;
			object-fit: cover;
		}

		.greeked-image {
			background: @blackFade10 !important;
		}

	}

	.stop-button {
		float: left;
	}

	@media (min-width: @viewPortSmallWidth) {
		.dashboard {
			&.feature {
				border: none;
				margin: 0;
				padding: 0;
			}

			> div:last-child .carousel-rows {
				border-bottom: none;
			}
		}
	}
}
