.root {
	display: block;
}

.flickityCarouselContainer {
	composes: flickity-carousel-container from global;
	opacity: 0;

	:global(.flickity-prev-next-button) {
		top: 30%;
	}
}
