@import "~rev-less/global/variables";

bulk-edit-videos {
	@media (min-width: @viewPortMedWidth) {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.help-block {
		color: @white;
	}

	video-expiration {
		.col-lg-9 {
			width: 100%;
		}
	}

	tags-input {
		color: @black
	}

	.sidebar-form {
		margin: 0 -16px;
		padding: 18px 16px;
		overflow-y: auto;
	}
}

.bulk-edit-sidebar {
	.control-label {
		color: @white;
	}

	@media all and (min-width: @viewPortMedWidth) {

		.bulk-edit-settings {
			border-top: 1px solid var(--theme-primary-txt) !important;
		}

		.bulk-edit-cancel {
			border-bottom: 1px solid var(--theme-primary-txt) !important;
		}
	}
}

.bulk-edit-tags {
	background-color: @white;
}
