@import (reference) '~rev-less/global/mixins';
@import (reference) '~rev-less/global/variables';

@teamLinkPrimary: #108dda;

.team-container {
	.team-search {
		padding-top: 5px;
	}
}
.team-wrapper {
	margin-top: 20px;
	margin-bottom: 20px;

	.team-no-search {
		text-align: center;

		&:not(:first-child){
			display: none;
		}
	}

	.team-not-available {
		text-align: center;
	}

	.team-link-primary {
		color: @teamLinkPrimary;
		text-decoration: underline;
	}
}
.team-header {
	display: flex;
	align-items: stretch;
	padding: 10px 0 4px;
	flex-direction: column;

	@media (min-width: @viewPortSmallWidth) {
		flex-direction: row;
	}
}
.team-heading {
	font-weight: 700;
	flex-grow: 1;
	font-size: @type20;
	line-height: 2rem;

	:global(.chevron-right) {
		font-size: @type16;
	}

	@media (min-width: @viewPortSmallWidth) {
		align-self: flex-end;
	}
}
.team-box {
	border: 1px solid @borderColor;
	margin-bottom: 30px;
	display: block;

	&:focus {
		outline-offset: 2px;
	}

	.team-logo {
		&:extend(.WordBreak);
		&:extend(.HyphensAuto);
		overflow: hidden;
		position: relative;
	}

	.team-name {
		left: 50%;
		position: absolute;
		top: 50%;

		line-height: 1.2;

		margin-right: -47%;
		max-height: 100px;

		.Transform(translate(-50%, -50%));

		word-break: normal;
	}

	.team-name-wrapper {
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100% !important;
		opacity: .08;
		padding: 75px 0;
		text-align: center;
	}

	footer {
		background-color: @borderColor;
		padding: 5px;
	}

	.team-video-count {
		color: @darkBlue;
		font-weight: bold;
		margin-left: 5px;
	}
}
.team-controls {
	flex-grow: 0;
	flex-shrink: 0;
	text-align: right;
}
.team-control-items {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;

	@media (min-width: @viewPortSmallWidth) {
		align-items: center;
		justify-content: flex-end;
	}
}
.team-add-btn {
	margin-top: auto;
}
.team-search-container {
	width: 350px;
	margin-top: 10px;

	@media (min-width: @viewPortSmallWidth) {
		align-items: center;
		justify-content: flex-end;
	}

	input {
		width: 310px;
		padding-right: 37px;
	}

	.glyphicons {
		width: 40px;
		top: 4px;
		padding: 10px;
		font-size: 1.25em;
		border-left: none;
		right: -310px;
		cursor: pointer;
	}
}

.team-name {
	line-height: 1.2;
	margin: 0 0 .5% 0;
	word-break: break-all;
}

