@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.responsivePreview {
	position: relative;
	height: 0;
	padding-bottom: 56.25%;

	iframe {
		position: absolute;
		left: 0;
		top: 0;
	}

}

.embedPreviewContainer {
	display: flex;
	justify-content: center;
}

.previewWrap {
	position: relative;
	margin: 0 25px;
}
