@import (reference) "~rev-less/global/mixins";

@darkBackground: rgb(0, 62, 85) !important;
@inputLight: rgb(158, 156, 157);
@inputMedium: rgb(223, 223, 223);
@lightColor: rgb(252, 252, 253);
@headerButtonMargin: 2rem;

[flex="logo"] {
	.flexItem(1 1 75%);
}
.theme-Nettrix {

	branding-logo {
		display: flex;
		justify-content: center;

		img {
			height: 75px;

			@media all and (max-width: @viewPortMedWidth) {
				height: 49px;
			}

			@media all and (max-width: @viewPortSmallWidth) {
				height: 40px;
			}
		}
	}

	.main-nav {
		.TransitionAll();

		&-btn {
			box-shadow: none;
			font-size: @type14;
			height: 36px;
			width: 36px;

			&.dropdown-toggle:not(.btn-mobile) {
				font-size: @type24;
				padding: 0;
				margin-left: @headerButtonMargin;
				padding-bottom: 4px;

				&:not(.open) {
					&:hover {
						background-color: transparent !important;

						> span {
							display: inline-block;
						}
					}

					&:after {
						.AbsoluteFill;
						content: '';
						border-radius: 100%;
						transform: scale(1.25);
						opacity: .9;
						z-index: -1;
						margin: 1px;
					}
				}

				@media all and (max-width: @viewPortSmallWidth) {
					margin-left: 0;
				}
			}

			&:hover {
				.glyphicon, .glyphicons, .vb-icon {
					.Transform(scale(1.25));
				}
			}

			span + span {
				margin-left: 0;
			}
		}

		.btn {
			&.upload {
				background: transparent;
			}

		}

		.dropdown {
			&.open {
				.btn {
					.notifications,
					.upload,
					.go-live {
						//background: @darkBackground;
						border: 1px solid @lightBorder;
						border-bottom: none;
						border-bottom-right-radius: 0;
						border-bottom-left-radius: 0;
					}
				}

				&.search-icon {
					&:after {
						border-right: 1px solid @lightBorder;
						border-left: 1px solid @lightBorder;
						bottom: -30px;
						content: " ";
						height: 30px;
						left: @headerButtonMargin;
						position: absolute;
						width: ~'calc(100% - @{headerButtonMargin})';

						@media all and (max-width: @viewPortMedWidth) {
							bottom: -17px;
							height: 20px;
						}

						@media all and (max-width: @viewPortSmallWidth) {
							bottom: -11px;
							left: 0;
							width: 100%;
						}
					}

					.dropdown-toggle {
						border-bottom-right-radius: 0;
						border-bottom-left-radius: 0;
						border-top: 1px solid @lightBorder;
						border-left: 1px solid @lightBorder;
						border-right: 1px solid @lightBorder;

						&:before {
							.AbsoluteFill();
							content: " ";
						  }
					}
				}
			}
		}
	}

	.navbar {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: 3rem;
		padding-left: 80px;
		@media all and (max-width: @viewPortSmallWidth) {
			padding: 1rem;
		}
		z-index: 1;

		&-brand {
			display: flex;
			float: none;
			height: auto;
			justify-content: center;
			margin: 0 auto;
			padding: 0;
			width: 143px;
		}
	}

	.btn {
		&-mobile {
			padding: 0 8px;

			.icon-bar {
				margin-bottom: 4px;

				&:first-of-type {
					margin-top: 4px;
				}
			}
		}
		&.show-nav.btn-mobile-open {
			@media all and (max-width: @viewPortSmallWidth) {
				left: 165px;
			}
		}
	}

	#mobile-menu {
		display: block;
	}

	/* This will change once there is actual data integrated from Lalit backend work */
	.dropdown-menu {
		&.navdrop-menu {
			&.mobile-menu {
				display: block;
				top: 0;
				height: 100%;
				left: -250px;
				position: fixed;
				border-top: transparent;
				.TransitionAll();
				width: 250px !important;

				@media all and (max-width: @viewPortMedWidth) {
					width: 100%;
				}
				.accent-border {
					border-right-style: solid;
					border-right-width: 1px;
				}
				.mobile-menu-list {
					padding-left: 15px;
					margin-top: 91px;
					overflow-y: auto;
					height: ~'calc(100% - 91px)';
					@media all and (max-width: @viewPortMedWidth) {
						margin-top: 86px;
						height: ~'calc(100% - 86px)';
					}
					@media all and (max-width: @viewPortSmallWidth) {
						margin-top: 55px;
						height: ~'calc(100% - 55px)';
					}
					.menu-item-icon {
						top: -2px;
					}
				}
			}
		}
	}

	.open > .mobile-menu {
		left: 0 !important;
	}

	// right side dropdown open
	.dropdown.netrix-dropdown.open {
		&:before, &:after {
			border-right: 1px solid @lightBorder;
			border-left: 1px solid @lightBorder;
			bottom: -11px;
			content: " ";
			height: 20px;
			left: @headerButtonMargin;
			position: absolute;
			width: ~'calc(100% - @{headerButtonMargin})';
			z-index: 50000;

			@media all and (max-width: @viewPortSmallWidth) {
				height: 8px;
				left: 0;
				top: 36px;
				width: 100%;
			}
		}

		> .btn:before {
			.AbsoluteFill;
			content: " ";
		}

		.dropdown-toggle {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
			border-top: 1px solid @lightBorder;
			border-left: 1px solid @lightBorder;
			border-right: 1px solid @lightBorder;
		}

		.dropdown-menu {
			top: 52px;
			padding: 0;
			margin-top: 0 !important;

			&.notification-menu {
				border: 1px solid @lightBorder;
				right: -30px;
			}

			@media(min-width: (@viewPortSmallWidth + 1)){
				left: auto;
				padding: 0;
				right: -50px;
				top: 48px;
			}
		}
	}

	search-header-dropdown {
		.main-nav-btn {
			width: auto;
		}
		.search-icon-btn {
			width: 36px;
		}
	}
}
