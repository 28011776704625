@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

@mediaVideosMinWidth: 265px;
@mediaTeamsMinWidth: 40vw;
@teamLinksWrapperHeight: 180px;
@teamLinkHeight: 35px;
@teamLinkWidth: 12vw;

media-menu {
	color: @color1;
	text-transform: initial;

	&.dropdown-menu {
		border: 0;
		.BoxShadow(0 10px 20px @blackFade50);
		left: 21px !important;
		padding: 0;
		position: absolute;
		top: 52px !important;
		z-index: 1031;
	}

	> section {
		border-style: solid;
		border-width: 1px;
		display: flex;
		min-height: 180px;
		right: -25px;
		top: 10px;
		z-index: 1032;
	}

	.media-videos {
		flex: 0 0 20%;
		min-width: @mediaVideosMinWidth;
		padding: 1em 3em 1em 3em;
	}

	.media-teams {
		min-width: @mediaTeamsMinWidth;
		padding: 1em 3em;
		position: relative;
	}

	.no-teams {
		margin-top: 2em;
		text-align: center;
	}

	.media-box-header {
		display: inline-block;
		font-size: 150%;
		font-weight: normal;
		margin: 0;
	}

	.media-box-list {
		list-style: none;
		padding: 1em 0 0 0;

		> li:last-child .media-video-item {
			border-bottom: 0;
		}
	}

	.media-team-links-wrapper {
		padding: 1em 0 0 0;
		vertical-align: top;
		height: @teamLinksWrapperHeight;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
	}

	.media-video-item {
		border-bottom: 1px solid @color1Lighten50;
		color: @color1;
		display: block;
		padding: .75em 0;

		&.active, &:hover {
			color: @accentColor;
		}

		.text-label {
			font-size: @type16 !important;
		}
	}

	.media-team-link {
		font-size: 110%;
		display: block;
		.TextClip !important;
		height: @teamLinkHeight;
		width: @teamLinkWidth;
		padding-top: 7px;
		text-decoration: none !important;

		&:hover {
			text-decoration: none !important;
			box-shadow: inset 0 0 0 99999px rgba(255,255,255,0.2);
		}
	}

	.media-team-link-logo {
		height: 20px;
		width: 20px;
		display: inline-block;
		margin-left: 5px;
		margin-right: 5px;

		img {
			max-width: 100%;
			max-height: 100%;
			font-size: 0;
		}
	}

	.media-team-link-name {
		position: relative;
		top: -5px;
	}

	.arrow-container {
		display: block;
		height: 40px;
		left: -26px;
		position: absolute;
		top: -37px;
		width: 70px
	}

	.media-arrow-bg {
		.TriangleTop(14px, @blackFade50);
		left: 46px;
		position: absolute;
		top: 23px;
		z-index: 1;
	}

	.media-arrow-fg {
		.TriangleTop(12px, @color2Darken05);
		left: 48px;
		position: absolute;
		top: 26px;
		z-index: 2;
	}
	
	.teams-section-header {
		padding-bottom: 10px;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		display: flex;

		.teams-header-text-section {
			display: flex;
		}

		.header-link-wrapper {
			display: flex;
    		align-items: flex-end;
		}

		.all-teams-section {
			.header-link-wrapper;
			margin-left: auto;
		}

		.create-new-team-section {
			.header-link-wrapper;
			margin-top: 10px;
			@media all and (min-width: (@viewPortLargeWidth)) {
				padding-left: 10px;
				margin-left: 10px;
				border-left-width: 1px;
				border-left-style: solid;
				margin-top: 0;
			}
		}
		
		.teams-section-header-link {
			font-size: @type16;
			.glyphicons {
				font-size: @type12 !important;
				top: -1px;
				left: 2px;
			}
		}
	}

	@media all and (max-width: @viewPortMedWidth - 1px) {
		> section {
			display: none;
		}
	}
	@media all and (max-width: (@viewPortLargeWidth - 1px)) {
		.media-videos {
			min-width: 275px;
		}

		.media-arrow-bg {
			left: 37px;
		}

		.media-arrow-fg {
			border-bottom: 12px solid @color2Darken05;
			left: 39px
		}

		.all-teams-section {
			align-items: flex-start !important;
		}

		.media-team-links-wrapper {
			height: 255px;
		}

		.media-teams {
			width: 45vw;
		}

		.media-team-link {
			width: 20vw;
		}
		.teams-header-text-section {
			flex-direction: column;
		}

	}
	@media all and (min-width: @viewPortLargeWidth) {
		.arrow-container {
			left: -35px;
		}
	}
}
