@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.root {
	background: transparent !important;
	border: none !important;
	box-shadow: none !important;

	:global(.tile-item) {
		background: transparent !important;
		border: none !important;
		.RoundedShape();

		:global(.tile-footer) {
			background: transparent !important;
			border: none !important;

			&::before {
				content: none !important;
			}
		}
	}
}

.tileItem {
	color: transparent;

	:global(.grid-item-list) {
		background: @adminGray;
		.RoundedShape();

		:global(.fixed-ratio) {
			text-align: center;
			border: 2px solid transparent;
		}

		&:global(.fixed-ratio-wrapper::after) {
			padding-top: calc(56.25% + 13px);
		}

		:global(.no-item) {
			border: none;
			background: @gray;
		}
	}
}

.tileFooter {
	color: var(--theme-primary-txt) !important;
	height: 25px !important;
	font-size: @type18;
	line-height: 25px !important;
	padding: 0px !important;

	a {
		color: var(--theme-primary-txt) !important;
		font-size: @type16;
	}
}

.title {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin: 9px 0 9px 0;
}

.dummyChild {
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.NoVideosYet {
	vertical-align: middle;
	display: inline-block;
	font-size: @type16;
	color: var(--theme-primary-txt);

	&:hover {
		color: var(--theme-primary-txt);
	}
}
