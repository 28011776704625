@import "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.video-thumbnail-status {
	&:extend(.AbsoluteFill);

	&.fill {
		background: @gray30;
	}

	&.processing {
		background: @black;
		z-index: 1;
	}

	> span {
		position: absolute;
		left: 50%;
		top: 50%;
		.RoundedShape(50%);
		background: @color1Fade75;
		color: @color2;
		text-shadow: 0 0 3px @blackFade50;
		text-align: center;

		&.video-error {
			color: @warningColor !important;
		}
	}

	> .status-processing {
		background: none;

		.loader.small {
			border-color: @gray40;
			border-left-color: @white;
			margin: 0;
			.BoxShadow(0 0 3px @blackFade50);
		}
	}

	.status-inactive {
		background: none;
		color: @white;
		opacity: .85;
		z-index: 1;
	}
}

.tile-item {
	.video-thumbnail-status {
		> span {
			font-size: 30px;
			margin-left: -19px;
			margin-top: -19px;
			height: 38px;
			width: 38px;
			line-height: 38px;
		}

		> .status-processing {

			.loader.small {
				top: -2px;
			}
		}
	}
}

.file-list-row, .tableViewRow {
	&.rejected {
		.video-thumbnail-status {
			background: @warningColor;
		}
	}

	&.pending-approval {
		.video-thumbnail-status {
			background: @accentFade50;
		}

		&.video-owner {
			.video-thumbnail-status {
				background: @highlightFade50;
			}
		}
	}

	.video-thumbnail-status {
		> span {
			font-size: 18px;
			margin-left: -12px;
			margin-top: -12px;
			height: 24px;
			width: 24px;
			line-height: 24px;
			z-index: 1;
		}
	}

	.status-processing {
		height: 18px;
	}
}
