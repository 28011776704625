@import (reference) "~rev-less/global/mixins";

vb-media-table,
vb-media-table-column,
vb-media-table-column-header,
vb-media-table-select-column-header {
	display: block;

	.table-cell {
		display: block;
	}

	&[flex="none"] {
		.flexItem(none) !important;
	}

	speech-search-results-list {
		order: 500;
	}

	//Doing this because if any animation happening in side table
	//it's breaking the whole page in chrome.
	&.stop-3d-transform {
		.Transform(translate3d(0, 0, 0));
	}
}

vb-media-table.file-list-wrap {
	overflow: visible !important;
}

vb-media-table-column {
	.video-status-public-password {
		background: @color1Fade75;
		color: @white;
		font-size: @type22;
		line-height: 1;
		padding: .25rem;
		position: absolute;
		top: 0;
		left: 0;
		.RoundedShape(.5rem);
	}

	video-360-indicator {
		display: inherit;
		padding: 0 .5rem;
	}

	unlisted-video-indicator {
		position: relative;
		top: 4px;
		left: 10px;
		display: inherit;
	}
}
