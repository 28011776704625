@import (reference) "~rev-less/global/variables";


.RoundedShape(@radius:@defaultBorderRadius) {
	border-radius: @radius;
}

.RoundedShape(@topLeft; @topRight; @bottomRight; @bottomLeft) {
	.RoundedShape(@arguments);
}

.RoundedTopShape(@radius:@defaultBorderRadius) {
	.RoundedShape(@radius; @radius; 0; 0);
}

.RoundedBottomShape(@radius:@defaultBorderRadius) {
	.RoundedShape(0; 0; @radius; @radius);
}

.RoundedRightShape(@radius:@defaultBorderRadius) {
	.RoundedShape(0; @radius; @radius; 0);
}

.RoundedLeftShape(@radius:@defaultBorderRadius) {
	.RoundedShape(@radius; 0; 0; @radius);
}

.Round {
	.RoundedShape(9999px)
}

.RoundedButton {
	.RoundedShape()
}

.BorderTopLeftRadius(@radius:@defaultBorderRadius) {
	border-top-left-radius: @radius;
}

.BorderTopRightRadius(@radius:@defaultBorderRadius) {
	border-top-right-radius: @radius;
}

.BorderBottomLeftRadius(@radius:@defaultBorderRadius) {
	border-bottom-left-radius: @radius;
}

.BorderBottomRightRadius(@radius:@defaultBorderRadius) {
	border-bottom-right-radius: @radius;
}

@defaultShadow: 0 0 2px @black;
.BoxShadow(@values:@defaultShadow) {
	//-webkit-box-shadow: @values; // iOS 5-, Android 3-
	box-shadow: @values;
}

@defaultColumnCount: 2;
.Columns(@count:@defaultColumnCount) {
	-webkit-columns: @count;
	columns: @count;
}

@defaultColumnGap: normal;
.ColumnGap(@gap: @defaultColumnGap) {
	-webkit-column-gap: @gap;
	column-gap: @gap;
}

.PointerCursor {
	cursor: pointer;
}

.Placeholder(@rules) {

    &::-webkit-input-placeholder {
        @rules();
    }
    &:-moz-placeholder {
        @rules();
    }
    &::-moz-placeholder {
        @rules();
    }
    &:-ms-input-placeholder {
        @rules();
    }
}

.TextClip {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;

	.HyphensAuto;
}

.whiteSpacePreWrap {
	 white-space: pre-wrap;       /* css-3 */
	 word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.HyphensAuto { //not standard, so these are all needed
	//NOTE: not supported by Chrome as of 44
	-webkit-hyphens: auto; //Safari 5.1+, iOS 4.3+
	-moz-hyphens: auto;// FF
	-ms-hyphens: auto; //IE10+
	//hyphens: auto; //presently not supported by anyone
}

.WordBreak { //Be VERY careful with this, breaks normal words wh_erever
	word-break: break-all;
	word-break: break-word;  //webkit (treated as word-wrap: break-word)
	overflow-wrap: break-word; //Chrome, Safari 6.1+, iOS 7.1+, Android 4.4+ (word-break is the legacy name)
}

@defaultTransitionAll: all linear 0.25s;
.TransitionAll(@values:@defaultTransitionAll) {
	-webkit-transition: @values; //Android 4.3-
	transition: @values; //IE10, FF, Chrome, Safari 6.1+, iOS 7.1+, Android 4.4+
}

.Transform(@values) {
	-webkit-transform: @values; //still used by mobile + Safari desktop
	transform: @values; //IE10+, FF, Chrome 36+
}

.TransformOrigin(@values) {
	-webkit-transform-origin: @values; //still used by mobile + Safari desktop
	transform-origin: @values; //IE10+, FF, Chrome 36+
}

.GlyphiconFont {
	font-family: @glyphiconFont;
}

.ButtonGlyphicons {
	font-size: @glyphiconSizeBase;
	top: 3px;
}

@defaultBlur: 1px;
@defaultBlurOpacity: 0.9;
.Blur(
	@blur: @defaultBlur;
	@opacity: @defaultBlurOpacity;
) {
	.filter(blur(@blur));
	opacity: @opacity;
}

.filter(@filter) {
	filter: @filter;
}

.clearFix {
	clear: both;
	content: "";
	display: table;
}

/**
 * Flex Layout Support
 * ----------------
 * Prefixed = IE10, Safari 8-, iOS 8.1-, Android 4.3-
 * Standard = IE11+, Chrome, Android 4.4+
 */

.flexDisplay {
	display: -webkit-flex;
	display: flex;
}

.flexContainerStatic {
	display: -webkit-box;
	.flexDisplay;
}

@defaultFlexWrap: nowrap;
@defaultFlexAlignItems: stretch;
@defaultFlexjustify: space-between;
@defaultFlexDirection: row;
.flexContainer (
	@wrap: @defaultFlexWrap;
	@stretch: @defaultFlexAlignItems;
	@justify: @defaultFlexjustify;
	@direction: @defaultFlexDirection
) {

	display: -webkit-box;
	display: -webkit-flex;
	display: flex;

	-webkit-flex-flow: @direction @wrap;
	flex-flow: @direction @wrap;

	-webkit-align-items: @stretch;
	align-items: @stretch;
	justify-content: @justify;
}

.displayFlex(){
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
}

@defaultFlexItem: 0 0 auto;
.flexItem(@flex:@defaultFlexItem) {
	-webkit-flex: @flex;
	flex: @flex;
}


.flexJustifyContent(@value){
	-webkit-justify-content: @value;
	justify-content: @value;
}

.flexAlignContent(@value){
	-webkit-align-content: @value;
	align-content: @value;
}

.flexFlow(@direction, @wrap:~'') {
	-webkit-flex-flow: @direction @wrap;
	flex-flow: @direction @wrap;
}

.flexWrap(@wrap) {
	-webkit-flex-wrap: @wrap;
	flex-wrap: @wrap;
}

.flexGrow(@growFactor) {
	-webkit-flex-grow: @growFactor;
	flex-grow: @growFactor;
}

.flexShrink(@shrinkFactor) {
	-webkit-flex-shrink: @shrinkFactor;
	flex-shrink: @shrinkFactor;
}

.flexBasis(@flexBasis) {

	// IE 10 does not support this

	-webkit-flex-basis: @flexBasis;
	flex-basis: @flexBasis;
}

.flexDirection(@flexDirection) {
	-webkit-flex-direction: @flexDirection;
	flex-direction: @flexDirection;
}

.flex(@flex) {
	-webkit-flex: @flex;
	flex: @flex;
}

.alignItems(@align) {
	-webkit-align-items: @align;
	align-items: @align;
}

.alignSelf(@align) {
	-webkit-align-self: @align;
	align-self: @align;
}

/*
 * IE9 not supported
 * IE10+ unprefixed
 * FF unprefixed
 * Safari 6.1+ unprefixed
 * Chrome 26+ unprefixed
 * Android 4.0 - 4.3 prefixed, 4.4+ unprefixed
 */
.VerticalGradient(@startColor: @white, @endColor: @black) {
	background: linear-gradient(to bottom, @startColor, @endColor);
}

.VerticalGradient(@startColor, @endColor, @inlineSvgData) {
	.VerticalGradient(@startColor, @endColor);
}

.HorizontalGradient(@startColor: @white, @endColor: @black) {
	background: linear-gradient(to right, @startColor, @endColor);
}

.HorizontalGradient(@startColor, @endColor, @inlineSvgData) {
	.HorizontalGradient(@startColor, @endColor);
}

.LinearGradient(@angle; @colorStops;) {
	background-image: linear-gradient(@angle, @colorStops);
}

@defaultTriangleSize: 8px;
@defaultTriangleColor: @white;
.TriangleTop(@size:@defaultTriangleSize, @color:@defaultTriangleColor){
	border-style: solid;
	border-width: 0 @size @size @size;
	border-color: transparent transparent @color transparent;
}

.TriangleBottom(@size:@defaultTriangleSize, @color:@defaultTriangleColor) {
	border-style: solid;
	border-width: @size @size 0 @size;
	border-color: @color transparent transparent transparent;
}

.TriangleRight(@size:@defaultTriangleSize, @color:@defaultTriangleColor) {
	border-style: solid;
	border-width: @size 0 @size @size;
	border-color: transparent transparent transparent @color;
}

.TriangleLeft(@size:@defaultTriangleSize, @color:@defaultTriangleColor) {
	border-style: solid;
	border-width: @size @size @size 0;
	border-color: transparent @color transparent transparent;
}

.AbsoluteFill {
	position: absolute;
	top: 0;
	right:0;
	bottom:0;
	left: 0;
}

.FixedFill {
	position: fixed;
	top: 0;
	right:0;
	bottom:0;
	left: 0;
}

.BtnWhite {
	.VerticalGradient(@startColor: @white, @endColor: @color2Lighten03);
	border-color: @borderColorDark;
	.BoxShadow(inset 0 1px 0 @white);
	color: @color1;
}

.BtnWhiteActive {
	color: @accentDarken20;
	border-color: @accentDarken20Fade25;
	background: @accentLighten45;
	.BoxShadow(none);
}

.focusedInput {
	border-color: @accentColor;
	outline: 0;
	.BoxShadow(inset 0 0 0 1px @accentColor, 0 0 10px @blackFade25;);
}

.focusedItem {
	/* bootstrap */
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

@defaultBoxSizing: content-box;
.BoxSizing(@boxSizing:@defaultBoxSizing) {
	//-webkit-box-sizing: @boxSizing; //Safari 5-, iOS 4.3-, Android 3-
	box-sizing: @boxSizing; //IE8+, FF, Safari, iOS 5.1+, Android 4+
}

.Animation(@animation) {
	-webkit-animation: @animation; //Chrome 42-, Safari 8, iOS Safari 8.1, Android 37
	animation: @animation; //IE10+, FF, Chrome 43+
}

.AnimationDirection(@animationDirection) {
	-webkit-animation-direction: @animationDirection; //Chrome 42-, Safari 8, iOS Safari 8.1, Android 37
	animation-direction: @animationDirection; //IE10+, FF, Chrome 43+
}

.AnimationIterationCount(@animationIterationCount) {
	-webkit-animation-iteration-count: @animationIterationCount; //Chrome 42-, Safari 8, iOS Safari 8.1, Android 37
	animation-iteration-count: @animationIterationCount; //IE10+, FF, Chrome 43+
}

.AnimationFillMode(@animationFillMode) {
	-webkit-animation-fill-mode: @animationFillMode; //Chrome 42-, Safari 8, iOS Safari 8.1, Android 37
	animation-fill-mode: @animationFillMode; //IE10+, FF, Chrome 43+
}

.AnimationPlayState(@animationPlayState) {
	-webkit-animation-play-state: @animationPlayState; //Chrome 42-, Safari 8, iOS Safari 8.1, Android 37
	animation-play-state: @animationPlayState; //IE10+, FF, Chrome 43+
}

@defaultUserSelect: none;
.UserSelect(@userSelect:@defaultUserSelect) {
	-webkit-user-select: @userSelect;
	-moz-user-select: @userSelect;
	-ms-user-select: @userSelect; //IE10+
	user-select: none;
}

.BackgroundClip(@backgroundClip) {
	//-webkit-background-clip: @backgroundClip;
	background-clip: @backgroundClip; //IE9+, FF, Chrome, Safari 7+, iOS 7.1+, Android 3+
}

.AbsoluteCenter {
	position: absolute;
	top: 50%;
	left: 50%;
	.Transform(translateY(-50%) translateX(-50%));
}

.VerticalCenter {
	position: relative;
	top: 50%;
	.Transform(translateY(-50%));
}

.ScrollContainer {
	.AbsoluteFill;
	overflow-y: scroll;
}

.primary-color {
	color: @accentColor;
}

.BackfaceVisibility(@visibility) {
	-webkit-backface-visibility: @visibility;
	backface-visibility: @visibility;
}

.Appearance(@appearance) {
	-webkit-appearance: @appearance;
	-moz-appearance: @appearance;
	appearance: @appearance;
}

.CursorGrab() {
	cursor: default;
	cursor: hand;
	cursor: move;
	cursor: -moz-grab;
	cursor: -webkit-grab;
	cursor: grab;
}

.CursorGrabbing() {
	cursor: default;
	cursor: hand;
	cursor: move;
	cursor: -moz-grabbing;
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

.Brightness(@brightness) {
	-webkit-filter: brightness(@brightness);
	-moz-filter: brightness(@brightness);
	-ms-filter: brightness(@brightness);
	filter: brightness(@brightness);
}

.LineClamp(@numLines) {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: @numLines;
	overflow: hidden;
}
