@import (reference) "~rev-less/global/mixins";
@import (reference) "~rev-less/global/variables";

video-playlist-carousel {
	@video-playlist-text-color: @whiteFade75;

	background-color: @blackFade50;
	box-sizing: content-box;
	display: block;
	height: 105px;
	flex: 0 0 105px;

	> .carousel-container {
		padding: 0;
		position: relative;
		width: 100%;
		height: 100%;
	}

	.flickity-carousel {
		height: 100%;
	}

	.carousel-cell {
		font-size: 13px;
		line-height: 15px;
		font-weight: bold;
		display: inline-block;
		width: 275px;
		position: absolute;
		word-wrap: break-word;

		&.selected {
			button {
				background: @accentColor;
			}

			.cell-title {
				color: @white;
			}

			img {
				border: 1px solid @white;
			}
		}

		.cell-title {
			color: @video-playlist-text-color;
		}

		button {
			display: flex;
			width: calc(100% - 6px);
			margin: 4px 3px;
			padding: 10px 5px;
			border: none;
			border-radius: 3px;
			flex-direction: row;
			background: @whiteFade25;
			flex-wrap: nowrap;
			overflow: hidden;
			word-break: break-all;

			&:hover {
				background: @accentFade50;
				.cell-title {
					color: @white;
				}
			}
		}
	}

	.img-wrap{
		position: relative;
		width: 135px;
		margin-right: .5rem;
		flex: 0 0 135px;
		.RoundedShape();

		> div {
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			background-color: black;
		}
	}

	.btn-carousel {
		width: 50px;
		color: @white;
		background-color: @blackFade50;
		border: 0;
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: 10;

		&[disabled] {
			opacity: .1;
		}
	}

	.btn-carousel-prev {
		left: 0;
	}

	.btn-carousel-next {
		right: 0;
	}

	.duration-overlay {
		position: absolute;
		bottom: 0px;
		right: 0px;
		background: @color1Fade75;
		border-radius: 3px;
	}

}
