@import (reference) '~rev-less/global/variables';

speech-search-results-list {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 10px;

	.speech-search-results-list-expander {
		color: var(--theme-primary-txt);
		display: inline-block;
		line-height: 2;
		font-weight: bold;
		.glyphicons {
			font-size: @type10;
		}
	}
}
