@import '~rev-less/global/variables';
@import '~rev-less/global/mixins';

@darkBackground: rgb(0, 62, 85) !important;
@inputMedium: rgb(223, 223, 223);
@lightColor: rgb(252, 252, 253);

vb-ui-typeahead-angular {
	display: block;
}

.theme {
	&-Nettrix {
		search-header-dropdown {
			.search-cell {
				border-radius: 0;
				z-index: -1;
			}

			.search-cell {
				border-top: 1px solid @lightBorder;
				border-bottom: 1px solid @lightBorder;
				position: fixed;
				top: 90px;
				width: 100%;

				vb-ui-typeahead-angular {
					input[vbUiTextInput] {
						background: @inputMedium;
						.BoxShadow(none);
						display: block;
						top: 5px;

						@media all and (max-width: @viewPortMedWidth) {
							top: 6px;
						}

						&:focus {
							border: none;
							.BoxShadow(none);
						}
					}
					typeahead-container .dropdown-menu {
						top: 7px !important;
					}
				}

				[role="search"] {
					height: 68px;
					margin: 0 auto;
					padding: .75rem;
					width: 75%;

					@media all and (max-width: @viewPortSmallWidth - 1) {
						padding: 15px;
						width: 100%;
					}
				}
			}

			.btn.main-nav-btn {
				margin-top: 0;
			}
		}
	}
}
