@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.container {
	display: flex;
	flex-direction: column;

	@media(min-width: @viewPortMedWidth) {
		flex-direction: row;
	}
}

.panelHeading {
	border-bottom: 1px solid @borderColor;
	margin: 0;
	padding: 15px !important;
	.RoundedTopShape();

	h4 {
		color: @color1MedText;
		text-transform: uppercase;
		font-size: @type16;
		text-shadow: 0 1px 1px @white;
		font-weight: bold;
		vertical-align: middle;
	}
}

.column {
	flex: 1;
	border-top: 2px solid @borderColor;
	border-bottom: 2px solid @borderColor;
	padding: 10px;
	margin: 10px;

	.columnTitle {
		font-size: @type18;
	}
}

.helpIcon {
	color: @adminGrayFade50;
}
