.theme-TwoTier,
.theme-Classic {
	mobile-menu {
		.dropdown-menu.mobile-menu {
			background: @color1;
			max-height: 90%;
			left: 0;
			margin: 0;
			overflow-y: auto;
			padding-bottom: 50px;
			position: fixed;
			right: 0;

			&-list {
				margin-top: 0;
			}

			.main-nav-btn {
				background: @whiteFade10 !important;
				color: @whiteFade50;
			}
		}

		.main-nav-btn {
			.RoundedShape(3px);
			height: 36px;
			width: 36px;

			.glyphicons {
				font-size: 24px;
				vertical-align: middle;
				top: 0;
			}
		}

		.btn-mobile-open {
			left: 0;
			margin-left: 0;
		}

		.theme-header {
			background-color: @color1 !important;
		}

		.open {
			.dropdown-toggle {
				&:after, &:before {
					display: none;
				}
			}
		}

		.mobile-menu-list {
			li.active {
				background-color: transparent;
				&:after {
					background-color: initial;
					width: 0;
				}
			}
		}

		.mobile-menu-list-item {
			&.isOpen, &:hover {
				background-color: @blackFade10;
			}
			.help-menu-list, .media-box-list {
				list-style-type: none;

				a {
					padding-left: 25px !important;
				}
			}
			a, button {
				color: fade(@white, 80%) !important;
				font-size: @type16;
				margin-left: 0 !important;
				text-align: left;
				text-decoration: none;

				&.active {
					background: @blackFade25;
					color: @white;
				}

				&:hover, &:focus {
					text-decoration: none !important;
					background: @blackFade10 !important;
					color: @accentLighten30 !important;
				}
			}

			&.active {
				&:after {
					background-color: initial;
					width: 0;
				}
			}

			&.theme-accent-btn:hover {
				background-color: @accentColor !important;
			}

			.glyphicon {
				color: @white;
			}
		}

		search-header .form-control {
			&:not(:hover):not(:focus) {
				color: @whiteFade50 !important;
			}

			.Placeholder({
				color: @whiteFade50 !important;
			})
		}
	}
}
