@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

@classicGlyphiconsSize: @type18;
@classicControlHeight: 36px;
@classicControlMinWidth: 40px;
@navBarHeight: 57px;

.theme-Classic {
	font-size: @type14;

	nav {
		@media all and (max-width: @viewPortMedWidth - 1) {
			min-height: 53px;
			position: relative;
		}
	}

	.brand-cell {
		text-align: center;

		@media all and (max-width: @viewPortMedWidth - 1) {
			position: absolute;
			left: 0;
			right: 0;
		}

		@media(max-width: @viewPortMobileDeviceWidth) {
			right: 30% !important;
		}

		.navbar-brand {
			display: inline-block;
			height: auto;
			float: none;
			margin-top: 0;
			padding: 0;

			img {
				height: 40px;

				@media all and (max-width: @viewPortMedWidth - 1) {
					height: 36px;
				}

				@media(max-width: @viewPortMobileDeviceWidth) {
					height: 25px !important;
				}
			}
		}
	}

	.main-nav-btn {
		.BoxShadow(1px 1px 1px 1px @color1Fade50);
		font-size: @type14;
		height: @classicControlHeight;
		min-width: @classicControlMinWidth !important;
		top: 1px;

		&:not(.theme-accent-bg) {
			background: hsla(0, 0%, 100%, .1);
		}

		.glyphicons {
			font-size: @classicGlyphiconsSize;
			top: 0;
			vertical-align: middle;
		}

		.vb-icon {
			font-size: @classicGlyphiconsSize;
			vertical-align: top;
		}
	}

	.main-nav-links {
		position: relative;

		> a,
		> button {
			display: inline-block;
			margin-right: 30px;
			height: @navBarHeight;
			line-height: @navBarHeight;
			text-shadow: none;

			&:hover {
				text-decoration: none;
			}
		}

		> .pip {
			height: 7px;
			width: 65%;
			position: absolute;
			bottom: 0;
			left: 0;
		}

		.glyphicons:not(vb-caret) {
			font-size: @classicGlyphiconsSize;
			top: 0;
			vertical-align: middle;
		}

		&:not(.active):not(:hover) > .pip {
			background-color: transparent !important;
		}
	}

	.search-cell vb-ui-typeahead-angular input {
		background-color: hsla(0,0%,100%,.3);
		border: none;
		top: 1px;
		position: relative;
		.BoxShadow(1px 1px 1px 1px @color1Fade50);

		&:hover,
		&:focus {
			background-color: @white;
			border: none;
			.BoxShadow(1px 1px 1px 1px @color1Fade50);
		}

		&:hover {
			.Placeholder({
				color: @gray !important;
			})
		}

		&:focus {
			.Placeholder({
				color: transparent !important;
			})
		}

		[role="search"] {
			width: 100%;
		}

		&.custom-border {
			border: 1px solid !important;
			&:hover {
				.Placeholder({
					color: inherit !important;
				})
			}
		}
	}

	.utility-section {
		margin: 0 2px;
	}

	notifications-menu-button .main-nav-btn.notifications {
		font-size: @type14;
	}

	.admin-cell {
		margin-top: 1px;
	}

	.upload {
		position: relative;

		.glyphicons {
			z-index: 3;
		}

		&-cell {
			.dropdown-menu {
				@media all and (max-width: @viewPortSmallWidth) {
					top: 70px !important;
				}
			}
		}
	}

	.go-live-menu {
		@media(max-width: @viewPortSmallWidth) {
			top: 66px !important;
		}
	}

	.open .dropdown-toggle {
		&:before {
			content: " ";
			position: absolute;
			.TriangleTop(14px, @black);
			left: 50%;
			margin-left: -14px;
			bottom: -17px;
			@media all and (max-width: @viewPortSmallWidth - 1) {
				bottom: -22px;
			}
			z-index: 5001;
		}
	}

	.dropdown-menu.navdrop-menu {
		line-height: 1;
		left: auto;
		top: 54px;
		margin: 0;
		padding: 0;
		background: @color2Lighten05;
		border: 1px solid;
		border-color: var(--theme-header-txt);
		.RoundedShape();
		.BoxShadow(0 10px 20px @blackFade50);
		z-index: 5000;

		.modal-open & {
			z-index: 910;
		}
	}

	.dropdown-menu.navdrop-menu:not(.upload-media) :not(.typeahead-match-active) {
		a,
		.list-group-item {
			background: none;
			color: @color1MedText;
			border: none;
			white-space: inherit;
			margin-bottom: 0;

			.glyphicons {
				color: @color1LightText;
				font-size: @type16;
			}
		}
	}

	.dropdown-align-left {
		.dropdown-menu {
			left: 8px;
			right: auto;
		}

		&.open .dropdown-toggle {
			&:before,
			&:after {
				left: 23px;
			}
		}
	}

	.align-right .dropdown-menu.navdrop-menu {
		right: 0;
	}

	media-menu {
		@media all and (max-width: 75em) {
			&.dropdown-menu {
				left: -32px !important;
			}
			.media-arrow-bg {
				left: 90px;
			}
			.media-arrow-fg {
				left: 92px;
			}
		}
	}

	media-menu-dropdown .open .dropdown-toggle:before {
		display: none;
	}

	vb-caret {
		font-size: @type12;
	}

	help-menu .help-nav {
		top: 59px;
	}

	.user-profile-menu {
		min-width: 0;
	}

	@media(max-width: @viewPortSmallWidth){
		.notification-menu,
		.upload-media {
			top: 65px !important;
		}
	}

	/* needed to offset a negative margin of 4px added in upload-menu.less */
	@media all and (min-width: (@viewPortSmallWidth + 1)) and (max-width: @viewPortMedWidth) {
		.upload-media {
			top: 58px !important;
		}
	}
}
