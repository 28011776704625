@import (reference) '~rev-less/global/variables';

.progressBarWrap {
	margin-top: 10px;
	background: @gray40;
	height: 3px;
	margin-right: 1%;
	width: 93%;

	@media all and (max-width: @viewPortMedWidth) {
		margin-top: 10px;
		margin-bottom: 10px;
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
}

.progressBar {
	height: 100%;
}
