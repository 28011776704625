@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.root {
	background: transparent !important;
	border: none !important;
	box-shadow: none !important;

	:global(.tile-item) {
		background: transparent !important;
		border: none !important;
		.RoundedShape();

		:global(.tile-footer) {
			background: transparent !important;
			border: none !important;

			&::before {
				content: none !important;
			}
		}
	}

	:global(.fixed-ratio) {
		.RoundedShape() !important;
	}

	.title {
		margin: 9px 0 9px 0;
		height: 2.2em;
		.LineClamp(2);
	}

	.videoOverlay {
		.AbsoluteFill;

		.bottomOverlay {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			flex-direction: column;
			z-index: 1;

			.progressBarWrap {
				bottom: 0;
				left: 0;
				right: 0;
				background-color: @blackFade40;

				.progressBar {
					height: 4px;
				}
			}

			.videoDurationOverlay {
				position: relative;
				top: unset;
				height: 19px;
				line-height: 2em;
				padding: 0px 10px;
				width: 45px;
				bottom:0;
				right: 0;
			}

			.liveBadge {
				position: relative;
				margin: 4px;
				top: unset;
				right: unset;
			}

			video-360-indicator {
				position: relative;
				top: unset;
				right: unset;
			}
		}
	}

	.videoInfo {
		display: grid;
		margin-left: 8px;
		color: var(--theme-primary-txt);

		span {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			line-height: 1.1em;
			font-size: @type13;
		}
	}

	.tileFooter {
		display: flex;
		padding: 0;

		vb-profile-picture {
			span {
				height: 35px;
				width: 35px;
				border-radius: 50%;

				img {
					height: 35px;
					width: 35px;
					border-radius: 50%;
				}

				:global(.user) {
					padding: 0 8px;
					height: unset;
				}
			}
		}

		.ownerName {
			padding-top: 2px;
		}
	}

	.tileItem {
		background: transparent !important;
	}

}

.responsiveRoot {

	.thumbnailWrapper {
		@media all and (max-width: (@viewPortMedWidth - 1px)) {
			height: 45px;
			flex-shrink: 0;
			margin-right: 10px;
			width: 75px;
		}
	}

	.tileFooter {
		vb-profile-picture {
			@media all and (max-width: (@viewPortMedWidth - 1px)) {
				display: none;
			}
		}
		.ownerName {
			@media all and (max-width: (@viewPortMedWidth - 1px)) {
				display: none;
			}
		}
	}

	.tileItem {
		@media all and (max-width: (@viewPortMedWidth - 1px)) {
			height: 45px;
			margin-bottom: 16px;
		}
	}

	.imageContainer {
		@media all and (max-width: (@viewPortMedWidth - 1px)) {
			display: flex;
		}
	}
}

.thumbnailPreview {
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
