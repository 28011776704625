@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.root {
	display: block;
	height: 100%;
}
.categoryAndVideo {
	padding-right: 23px;
}

.cellAnchor {
	color: var(--theme-primary-txt);
	&:hover, &:active {
		color: var(--theme-primary-txt);
		text-decoration: none;
	}
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	padding: 10px 0;
}

.name {
	composes: cellAnchor;
	font-size: @type16;
	padding: 0 23px;
	overflow: hidden;

	> div {
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}

	.title {
		font-size: @type20;
		font-weight: bold;
	}

	.subTitle {
		font-size: @type12;
		margin: 10px 0;
	}
}

.edit {
	composes: cellAnchor;
	width: auto !important;
	justify-content: flex-end;
}

.categoryThumbnailFixedRatio {
	text-align: center;
	border: 2px solid transparent !important;

	:global(.vb-icon) {
		font-size: 80px;
		position: relative;
		top: 20px;

		:global(.ua-apple) {
			top: auto;
		}
	}
}

.noVideoYet {
	position: relative;
	top: 40%;
	word-wrap: break-word;
	white-space: normal;
}

.thumbnailCell {
	margin: 10px 0;
	flex: 0 1 190px;
	color: white !important;
	align-items: center;
	text-align: center;
	text-decoration: none !important;

	:global(.vb-icon) {
		font-size: 24px;
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
	}
}
