@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

manage-team {
	.admin-team {
		.preview-wrapper {
			background-color: @color2;
			margin-top: 10px;
			margin-bottom: 5px;
			padding: 10px;
			width: auto;
		}
		.btn-file-wrapper {
			text-align: right;
		}
		.btn-file-wrapper,
		.team-logo {
			display: table-cell;
		}
		.team-logo {
			font-size: @type12;
			height: 75px;
			width: 75px;
	
			.circle {
				width: 50px;
				height: 50px;
				font-size: 32px;
				background: @blackFade50;
				color: @white;
				vertical-align: middle;
			}
		}
		.logo-info {
			font-size: @type14;
			font-style: italic;
		}
		.logo-preview-wrapper {
			padding-top: 5%;
		}
		.logo-preview {
			min-height: 75px;
			min-width: 100px;
		}
		.logo {
			max-height: 100%;
			max-width: 100%;
		}
		.company-name-preview {
			margin-left: 20px;
		}
		.color-title {
			font-size: @type14;
		}
	}
	
	.teams-panel {
		.color-picker-wrapper {
			border-top-left-radius: 0;
			left: 0;
		}
		.color-picker-wrapper:before {
			margin-left: -167px;
		}
		.color-picker-wrapper:after {
			margin-left: -166px;
			top: -15px;
		}
		.danger {
			color: @warningColor;
		}
	}
	
	.from-media-state {
		&.admin-team {
			margin-left: 0;
			margin-right: 0;
		}
		.col-xs-12 {
			padding-left: 0;
			padding-right: 0;

			.panel {
				margin-bottom: 0px;
				border: 0;
				.BoxShadow(initial);
				
				.media-state-row {
					padding-left: 16px;	
				}
			}
		}
	}
}