@import (reference) '~rev-less/global/variables';

speech-search-result {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 2;

	.speech-search-result-time {
		margin-right: 10px;
	}

	.speech-search-result-excerpt {
		em {
			font-weight: bold;
			font-style: normal;
		}
	}
}
