media-menu-dropdown {
	.dropdown-toggle, [dropdowntoggle] {
		padding-top: 2px;
	}

	button.dropdown-toggle {
		background: none;
		border: none;
	}
}
