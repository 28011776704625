@import (reference) "~rev-less/global/mixins";

.flex-table {
	.flexContainer(nowrap; center; space-between; column);
	.flexGrow(1);
	.flexShrink(1);
	overflow: visible; //so that dropdowns work in list items

	.scrollable {
		&:extend(.flexDisplay);
		.flexDirection(column);
		.flexGrow(1);
		.flexShrink(1);
		overflow-y: scroll;
		width: 100%;
	}

	.flex-row {
		.flexContainer(nowrap; center; space-between; row);
		.flexShrink(0);
		width: 100%;

		> div:not(.flex-table) {
			.flexItem(1 0 1px); //0 value basis without units (removed by minification) is not recognized by IE due to a bug
			white-space: nowrap;

			&.small {
				.flexItem(0 0 48px);
			}

			&.fill {
				.flexItem(2 1 1px); //0 value basis without units (removed by minification) is not recognized by IE due to a bug

				&.double {
					.flexItem(4);
				}
			}
		}
	}
}

