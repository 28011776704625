@import (reference) "~rev-less/global/variables";

.unlistedVideoStatus {
	background: @color1Fade75;
	display: inline-block;
	height: auto;
	padding: 0 @typeUnit1-2x;

	color: @white;
	font-size: @type14;
	font-weight: bold;
	line-height: 1.8;

	:global(.vb-icon) {
		line-height: 1.8;
	}
}

.onlyIconDisplay {
	background: transparent;

	:global(.vb-icon) {
		color: @color1Fade75;
		font-size: @type20;
	}
}