.admin select.form-control.ng-invalid.ng-dirty {
	border: 1px solid @warningColorFade50;
	color: @warningColor;
}

.customize-device.error {
	border: 1px solid @warningColor;
	.RoundedTopShape(4px);
	border-bottom: none;
}

.customize-device-error {
	background: none;
	border: 1px solid @warningColor;
	.BorderTopLeftRadius(0);
	.BorderTopRightRadius(0);
	border-top: 0;
}