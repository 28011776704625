@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.video-status {
	position: absolute;
	top: .5rem;
	left: .5rem;
	z-index: 2;

	.statusText(@bgColor, @color, @height) {
		background: @bgColor;
		display: inline-block;
		height: @height;
		padding: 0 @typeUnit1-2x;

		color: @color;
		font-size: @type14;
		font-weight: bold;
		line-height: 1.8;
		text-transform: uppercase;
	}

	&-text {
		display: inline-block;

		> span {
			.statusText(@highlightFade75, @color1, 24px)
		}

		&.rejected > span {
			background: @warningColor;
			color: @white;
		}

		&.pending > span {
			background: @accentColor;
			color: @white;
		}
		&.inactive > span {
			background: @color1Fade75;
			color: @white;
		}
	}

	&-legal {
		.statusText(@black, @white, auto);

		.lock {
			font-size: @type11;
		}
	}

	&-text, &-legal {
		margin-bottom: .15rem;
	}

	.video-status-public-password {
		background: @color1Fade75;
		color: @white;
		display: inline-block;
		font-size: @type32;
		line-height: 1;
		padding: .25rem;
		.RoundedShape(.5rem);
	}
}

.video-owner {
	.video-text-status {
		&.pending > span
		{
			background: @color1Fade75;
			color: @white;
		}
	}
}
