@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.portalDashboardContainer {
	.displayFlex();
}

.portalDashboardContent {
	flex: 1 1 80%;
	padding: 30px 4%;
	min-width: 70%;
	width: 70%;
}

.portalDashboardSidebar {
	border-left: 1px solid @lightBorder;
	flex: 1 1 20%;
	min-width: 30%;
	padding: 50px 3.33%;
	width: 30%;
}

.portalDashboardTopSeparator {
	border-top: 1px solid @lightBorder;
}

.carouselSection {
	margin-bottom: 30px;
}

@media all and (max-width: @viewPortLargeWidth) {
	.portalDashboardContainer,
	.portalDashboardContent,
	.portalDashboardSidebar {
		display: block;
	}

	.portalDashboardContent,
	.portalDashboardSidebar {
		width: 100%;
	}
}

@media all and (max-width: @viewPortSmallWidth) {
	.portalDashboardContent {
		padding: 2% 5%;
	}
}
