@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";


.titleAnchor {
	display: flex;
}

.title {
	text-overflow: ellipsis;
	overflow: hidden;
	flex-wrap: nowrap;
	line-height: 1.1;
}

.root {
	display: block;
	a {
		color: var(--theme-primary-txt);
		&:hover{
			color: var(--theme-primary-txt);
			text-decoration: none;
		}
	}

	:global(video-360-indicator) {
		color: var(--theme-primary-txt);
		text-shadow: none;
		:global(.vb-icon) {
			line-height: inherit !important;
		}
	}
}

.unlistedComp {
	:global(.vb-icon) {
		color: var(--theme-primary-txt) !important;
		line-height: 1 !important;
	}
}
