@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.txtArea {
	height: 140px;
	padding: 10px 16px;
	border-bottom: none;
	resize: none;
}

.actionWrapper {
	min-height: 40px;
	margin-top: -2px;
	padding: 5px;
	.displayFlex();
	.flexJustifyContent(flex-end);
}

.embedActions {
	.displayFlex();
	.flexJustifyContent(space-between);
}

.embedOptionWrapper {
	border-top-style: solid;
	border-top-width: 1px;
	border-color: inherit;
	.flex(1 1 100%);
	max-width: 100%;
	margin-top: 10px;
	padding: 10px 0;

	vb-toggle-button {
		width: 80px;
	}
}

.embedOptionCategory {
	.PointerCursor;
	font-size: @type18;
	padding: 0;
	margin: 10px 0;
}

.btn {
	margin-left: 5px;
	border: none;
	outline: none !important;
	box-shadow: none !important;
	background: none !important;
	color: var(--theme-primary-txt) !important;

	.light & {
		color: @black !important;
	}
}

.toolbarBtn {
	.btn;
	composes: btn from global;
	padding: 0;
	margin: 0;

	&:hover {
		text-decoration: underline;
	}
}

.sparkLogo {
	display: inline-block;
	position: relative;
	top: 4px;
	height: 20px;
	width: 20px;
	background-image: url(/img/webex-logo-new.png);
	background-size: contain;
	background-repeat: no-repeat;
}

.phoneLogo {
	top: 1px;
	font-size: @type18;
}

.label {
	.alignSelf(center);
	margin-right: 15px;
	width: 10%;
}
.info {
	font-size: @type14;
	margin: 0 0 10px;
}

.input {
	width: 30%
}

.inputSmall {
	padding: 5px;
	flex-basis: 60px
}

.inputRow {
	display: flex;
	align-items: center;
	margin-top: 15px;
	label {
		flex: 1 0 auto;
		font-size: 1rem;
	}
}

.sparkShare {
	:global(.modal-content) {
		overflow: visible;
	}
}

.startAt {
	margin-left: auto !important;
	flex: 0 0 40%;
}

.playerSize {
	width: 40%;
}

.playerDimensions {
	width: 60%;
	padding-left: 5px;

	>input, >label {
		width: 45%;
		margin: 0;
	}
}

.layoutBtn {
	position: relative;
	margin-bottom: 50px;
	margin-left: 30px;

	&:first-child {
		margin-left: 0;
	}

	button {
		margin-bottom: 10px;
		padding: 0;
		.RoundedShape;
		border: 1px solid var(--theme-primary-txt);
		background: @blackFade90;
		overflow: hidden;

		&.active{
			border-color: var(--theme-accent);
			outline: 2px solid var(--theme-accent);
		}
	}

	.btnLabel {
		position: absolute;
		left: 0;
		right: 0;
		text-align: center;
	}

	span:global(.vb-icon) {
		position: relative;
		top: 6px;
		font-size: 75px;

		:global(.ua-apple) & {
			top: auto;
		}
	}
}
