@import (reference) "~rev-less/global/mixins";
@import (reference) "~rev-less/global/variables";

@sideMargin: 16px;

.media-layout {

	.right-sidebar {
		&.is-open .right-sidebar-content {
			padding: 0;
			background-color: var(--theme-primary);
			color: var(--theme-primary-font);

			video-search-sidebar-header {
				margin: 0 -@sideMargin;
			}

			> .container-fluid, video-search-sidebar-header {
				padding: 0 @sideMargin;

				> header {
					position: static;
					margin: 0 -@sideMargin;
					padding: 1px @sideMargin;
					border-width: 0;
				}

				.sidebar-form {
					margin: 0 -@sideMargin;
					padding: 18px @sideMargin;
				}

				.uib-datepicker-popup{
					left: initial !important;
					right: 0;
				}

				.required.has-error .error-field{
					display: block;
				}

				.required-field:before{
					left: auto;
					right: -16px;
					top: 25px;
				}

				.error-field{
					padding-top: 5px;
				}

				label.control-label {
					font-size: 0.85em;
				}

				.acl-op{
					padding-bottom: 5px;
				}

				.acl{
					padding-top: 0;
					color: @black;
				}
			}

			vb-toolbar {
				.btn.btn-cancel:not(.active) {
					background: @backgroundLighten03;
				}
			}
		}
	}

	.sidebar-buttons {
		a, button {
			color: inherit;

			&:hover {
				color: inherit;
			}
		}

		.download-inventory-reports {
			.filetype {
				color: inherit;
				padding: 0;

				&:before {
					position: initial;
					font-size: 16px;
					color: inherit;
				}
			}
		}
	}

	@media(min-width: (@viewPortMedWidth)){
		.right-sidebar {
			/* prevents flicker when opening sidebar */
			width: unit(@rightSideClosedWidth, px);

			&.is-open:not(.force-closed) {
				overflow-y: hidden;
				width: unit(@rightSideOpenWidth, px);

				.right-sidebar-content {
					width: calc(unit(@rightSideOpenWidth, px) - unit(@rightSideClosedWidth, px));
				}
			}

			.sidebar-buttons{
				top: 0;
				bottom: 0;
			}
		}
	}

	@media (max-width: (@viewPortMedWidth - 1)) {
		.right-sidebar-content {
			overflow-y: scroll;
		}
	}
}
