@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.form {
	padding: 10px 20px;

	vb-ui-form-group {
		display: block;
		margin-bottom: 10px;
	}
}

.columnContainer {
	display: flex;
	justify-content: stretch;
	.flexWrap(nowrap);
	margin-top: 50px;

	@media all and (max-width: @viewPortLargeWidth - 1) {
		margin-top: 0;
		padding: 0 25px;
	}
}

.columnLeft {
	padding-right: 2rem;
	border-width: 0 1px 0 0;
	border-style: solid;
}

.columnRight {
	padding-left: 2rem;
}

.profilePicture{
	img {
		max-height: 200px !important;
	}
}

.loadingSpinner {
	.AbsoluteFill;
}

@media all and (max-width: @viewPortLargeWidth - 1) {
	.columnContainer {
		.flexWrap(wrap);
	}

	.columnLeft,
	.columnRight {
		flex: 100%;
	}

	.columnLeft {
		border-width: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.columnRight {
		margin-top: 30px;
		padding-left: 0;
	}
}
