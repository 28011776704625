@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

featured-videos {
	display: block;

	.featured-videos-wrap {
		position: relative;
		padding-bottom: 75px;
		min-height: 100vh;

		background-size: cover;
		background-repeat: no-repeat;

		@media(max-width: @viewPortSmallWidth){
			padding-bottom: 0;
			min-height: auto;
		}
	}

	.bottom-nav {
		position: absolute;
		bottom: 10px;
		left: 0;
		width: 100%;
		text-align: center;

		.down-link{
			font-size: 1.5em;
			.glyphicon {
				.Transform(rotate(90deg));
				text-shadow: 1px 2px 10px @blackFade50;
			}
		}

		@media(max-width: @viewPortSmallWidth){
			display: none;
		}
	}
}
