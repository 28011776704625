@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.listWrap {
	margin-bottom: 5px;
	min-height: 70px;
	padding-left: 10px;
}

.seeAllLink {
	position: absolute;
	right: 0;
	top: 0.75em;
	color: var(--theme-primary-txt);
}

.listItem {
	composes: theme-accent-link from global;
	list-style-position: inside;
	padding: 10px;
	.TextClip;
}

.listItemText {
	font-size: @type13;
}

.eventDate {
	font-size: @type12;
	padding: 10px 20px;
	color: var(--theme-primary-txt-fade-50);
	margin: 0 auto;
}

.listItemText,
.listItemText:hover {
	color: var(--theme-primary-txt);
}

.listItemText:hover {
	border-bottom: 1px solid @color0Fade95;
	text-decoration: none;
}

.noResultsContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 150px;
}
