@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

featured-video-player-carousel {
	.AbsoluteFill();
	display: block;

	vb-flickity-carousel, .flickity-carousel-container, .carousel-cell,
	.carousel-video {
		height: 100%;
	}

	vb-flickity-carousel {
		padding-top: 30px;
		padding-bottom: 50px;

		.transparent-header & {
			padding-top: 100px;
		}
	}

	.carousel-video {
		text-align: center;
		white-space: nowrap;
	}

	.carousel-video:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-right: -0.25em;
	}

	.carousel-item-wrapper {
		display: inline-block;
		vertical-align: middle;
		position: relative;
	}

	.carousel-cell {
		width: 100%;
		padding: 0;
	}

	.flickity-prev-next-button {
		background: @blackFade20;
		top: 50% !important;
		.TransitionAll();
		&.previous {
			left: 20px;
		}
		&.next {
			right: 20px;
		}
	}

	.flickity-page-dots {
		bottom: -33px;

		.dot {
			border-width: 1px;
			border-style: solid;
		}
	}

	.slide-info-overlay {
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		margin-top: 0;
		.VerticalGradient(fade(@black, 0%), @blackFade50);
		z-index: 50;
		max-width: 100%;
		width: 100%;
		.TransitionAll();
		color: @white;

		h1 {
			.TextClip;
			text-align: center;
			margin: 8px 16px;
			position: relative;
		}
	}

	.overlay-play {
		&:extend(.AbsoluteFill);
		text-align: center;
		z-index: 10;
		cursor: pointer;
		.TransitionAll();

		video-360-indicator {
			position: absolute;
			right: 16px;
			top: 2.75rem;
		}

		.circle {
			@radius: 25px;
			position: absolute;
			top: ~'calc(50% - @{radius})';
			left: ~'calc(50% - @{radius})';
			height: @radius * 2;
			width: @radius * 2;
			font-size: 30px;

			color: @whiteFade50;
			background: @color1Fade90;
			.TransitionAll();
		}

		.glyphicons {
			margin-top: 9px;
			margin-left: 2px;
		}
	}

	@media (max-width: @viewPortSmallWidth){
		background: @color2;
		border-bottom: 1px solid @borderColor;

		vb-flickity-carousel {
			padding: 0 0 48px;

			.transparent-header & {
				padding-top: 56px;
			}
		}

		.flickity-page-dots {
			position: absolute;
			left: 60px;
			right: 60px;
			bottom: -30px;
			margin: auto;
			width: auto;
		}

		.flickity-prev-next-button {
			top: auto;
			bottom: -68px;
			background: transparent;
		}
	}
}
