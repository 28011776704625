@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

notifications-menu-button {
	display: block;


	.notification-menu {
		width: 400px;
		left: auto;
		right: 0;

		@media(max-width: @viewPortSmallWidth){
			width: auto;
			position: fixed;
			left: 0 !important;
			right: 0 !important;
		}
	}

	&:not(.count-bubble) .high {
		background: @warningColor !important;
		color: @white !important;
	}

	&.count-bubble {
		.notifications {
			background-color: transparent;

			&:not(.open) {
				&:hover,
				&.high {
					background-color: transparent !important;
				}
			}

			&.open {
				.notifications-count {
					display: none;
				}
			}

			.notifications-count {
				border: 1px solid transparent;
				.RoundedShape(24px);
				padding: 3px;
				font-size: @type10;
				height: 20px;
				min-width: 1.5em;
				line-height: 1.25em !important;
				position: absolute;
				top: 0;
				right: 0;
			}

			&.high .notifications-count {
				color: @white;
				background: @warningColor;
			}
		}
	}
}
