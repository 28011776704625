@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.root {
	display: flex;
	width: 100%;
	height: 100%;
}

.thumbnailAnchor {
	display: flex;
	width: 100%;
	height: 100%;

	color: var(--theme-primary-txt);
	&:hover{
		color: var(--theme-primary-txt);
		text-decoration: none;
	}
}

.thumbnailWrapper {
	flex: 1 1 auto;
	position: relative;
}

.thumbnailPreview {
	border-radius: 6px;
	object-fit: contain;
}

.thumbnailSheetWrapper {
	.AbsoluteFill;
	z-index: 1;
}

.icon {
	z-index: 2;
	font-size: 18px;
	padding: 3px;
	color: @white;
	position: relative;
	float: left;
	background-color: @color1Fade50;
	border-radius: 3px;
	margin-left: 2px;

	@media (max-width: (@viewPortSmallWidth - 1)) {
		font-size: 10px;
	}
}

.vbIcon {
	composes: icon;
	top: 1px;
	@media (max-width: (@viewPortSmallWidth - 1)) {
		font-size: 15px;
	}
}

.thumbnailStatus {
	border-radius: 6px;
}
