@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

@leftTilePadding: 4px;
@sidebarAtBottom: 991px;
@wrapperMargin: 20px;
@outerTilePadding: 16px;

.listMainContent {
	margin-left: 20px !important;
	margin-right: 20px !important;
	height: 100%;

	@media (max-width: @sidebarAtBottom) {
		margin-right: auto !important;
		margin-left: auto !important;
	}
}

.embedPreviewContent {
	overflow: auto !important;
	.AbsoluteFill();
}

.tilesMainContent {
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	margin-top: 16px;
	overflow-y: auto !important;
	padding-right: 40px !important;
	padding-left: calc(40px + @leftTilePadding) !important;

	@media (max-width: @sidebarAtBottom) {
		padding-left: 16px !important;
		padding-right: 16px !important;
	}
}

.playlistSidebar {
	height: 600px;
	display: block;
}
